/********** Template CSS **********/
:root {
  --primary: black;
  --secondary: #daba48;
  --light: #f3f3f3;
  --dark: #212121;
  --grey: #c4b9b9;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-size: inherit;
  margin-left: 0;
}
.bannerstyle{
  object-fit: cover;
  width: 100%;
  height: 400px;
}

.btn {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  transition: 0.5s;
}

.btn-primary {
  color: #ffffff;
}

.btn-square {
  width: 36px;
  height: 36px;
}

.btn-sm-square {
  width: 28px;
  height: 28px;
}

.btn-lg-square {
  width: 46px;
  height: 46px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 0;
  border-radius: 0;
  z-index: 99;
}

.navbar-light .navbar-nav .nav-link {
  font-family: "Roboto", sans-serif;
  position: relative;
  margin-left: 30px;
  padding: 30px 0;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--dark);
  outline: none;
  transition: 0.5s;
}

.sticky-top.navbar-light .navbar-nav .nav-link {
  padding: 20px 0;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (min-width: 992px) {
  .navbar-light .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 7px;
    top: -4px;
    left: 50%;
    background: var(--primary);
    transition: 0.5s;
  }

  .navbar-light .navbar-nav .nav-link:hover::before,
  .navbar-light .navbar-nav .nav-link.active::before {
    width: 100%;
    left: 0;
  }

  .navbar-light .navbar-nav .nav-link.nav-contact::before {
    width: 100%;
    height: 1px;
    top: -1px;
    left: 0;
  }
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link {
    margin-left: 0;
    padding: 10px 0;
  }
}

.hero-header {
  background: url("../img/hero.jpg") top right no-repeat;
  background-size: cover;
  height: 550px; /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-header .content {
  padding: 10px 0; /* Adjust padding as needed */
}

.btn-play {
  position: relative;
  display: block;
  box-sizing: content-box;
  width: 16px;
  height: 26px;
  border-radius: 100%;
  border: none;
  outline: none !important;
  padding: 18px 20px 20px 28px;
  background: #ffffff;
}

.btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #ffffff;
  border-radius: 100%;
  animation: pulse-border 1500ms ease-out infinite;
}

.btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #ffffff;
  border-radius: 100%;
  transition: all 200ms;
}

.btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  left: -1px;
  border-left: 16px solid var(--primary);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
    opacity: 0;
  }
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #ffffff;
  background: #000000;
  opacity: 1;
}

.service-item a i {
  position: relative;
  padding-left: 20px;
  transition: 0.3s;
}

.service-item a:hover i {
  padding-left: 50px;
}

.service-item a i::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  top: 50%;
  left: 10px;
  margin-top: -1px;
  background: var(--primary);
  transition: 0.3s;
}

.service-item a:hover i::after {
  width: 50px;
}

.product-item {
  padding: 30px;
}

.product-item .btn-action {
  position: absolute;
  width: 100%;
  bottom: -40px;
  left: 0;
  opacity: 0;
  transition: 0.5s;
}

.product-item:hover .btn-action {
  bottom: -20px;
  opacity: 1;
}

.product-carousel .owl-nav {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.product-carousel .owl-nav .owl-prev,
.product-carousel .owl-nav .owl-next {
  position: relative;
  margin: 0 5px;
  width: 55px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: var(--primary);
  font-size: 22px;
  transition: 0.5s;
}

.product-carousel .owl-nav .owl-prev:hover,
.product-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

.bg-offer {
  background: url(../img/offer.jpg) top right no-repeat;
  background-size: cover;
}

.offer-header {
  background: url("../img/offer.jpg") top right no-repeat;
  background-size: cover;
  height: 500px; /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offer-header .content {
  padding: 10px 0; /* Adjust padding as needed */
}

.price-carousel::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  background: var(--primary);
  border-radius: 8px 8px 50% 50%;
  z-index: -1;
}

.price-carousel .owl-nav {
  margin-top: 35px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.price-carousel .owl-nav .owl-prev,
.price-carousel .owl-nav .owl-next {
  position: relative;
  margin: 0 5px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: #ffffff;
  font-size: 22px;
  border-radius: 45px;
  transition: 0.5s;
}

.price-carousel .owl-nav .owl-prev:hover,
.price-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

.team-carousel .owl-nav {
  position: absolute;
  width: 50px;
  height: 160px;
  top: calc(50% - 80px);
  right: 0;
  z-index: 1;
}

.team-carousel .owl-nav .owl-prev,
.team-carousel .owl-nav .owl-next {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: var(--primary);
  font-size: 22px;
  transition: 0.5s;
}

.team-carousel .owl-nav .owl-prev:hover,
.team-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

.team-item img {
  transition: 0.5s;
}

.team-item:hover img {
  transform: scale(1.2);
}

.team-item .team-overlay {
  position: absolute;
  top: 45px;
  right: 45px;
  bottom: 45px;
  left: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(122, 183, 48, 0.8);
  transition: 0.5s;
  opacity: 0;
}

.team-item:hover .team-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
}

.bg-testimonial {
  background: url(../img/testimonial.jpg) top left no-repeat;
  background-size: cover;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 46px;
  top: calc(50% - 23px);
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: var(--primary);
  font-size: 22px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

.testimonial-carousel .owl-item img {
  width: 100px;
  height: 100px;
}
/* Media query for responsiveness */
@media (max-width: 768px) {
  .bannerstyle {
    height: 250px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 480px) {
  .bannerstyle {
    height: 200px; /* Further adjust for very small screens */
  }
}

@media (min-width: 576px) {
  .blog-item .row {
    height: 300px;
  }
}

.blog-item a i {
  position: relative;
  padding-left: 20px;
  transition: 0.3s;
}

.blog-item a:hover i {
  padding-left: 50px;
}

.blog-item a i::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  top: 50%;
  left: 10px;
  margin-top: -1px;
  background: var(--primary);
  transition: 0.3s;
}

.blog-item a:hover i::after {
  width: 50px;
}
/* style.css */
.splide.small-carousel {
  max-width: "100%"; /* Adjust the maximum width of the carousel */
  
}

.splide.small-carousel .splide__slide img {
  width: 100%; /* Ensure images fill their container */
  border-radius: 8px; /* Optional: Add rounded corners to images */
}

/* .girdstyle {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 10px;
  background-color: #daba48;
} */

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.product-info {
  text-align: center;

  
}

/* .product-imagemain {
  height: 300px;
  width: 300px;
  object-fit: contain;
  
} */

.productimagestyle {
  width: 350px;
  height: 350px;
  object-fit: contain;
  padding: 10px;
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Example shadow */
}
.bounce{
  transition: transform 0.3s ease-in-out, 

}

.bounce:hover {
  transform: scale(1.05);

}

.view-more-button {
  padding: 10px 20px;
  
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.view-more-button:hover {
  
}


.product-image {
  width: 150px; /* Adjust image width as needed */
  height: 150px; /* Adjust image height as needed */
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}



.logostyle {
  height: 100px;
  width: 100px;
}

.sec-imageview {
  margin-top: 20px;
  /* background-color: #daba48 !important; */
}

.sideimage{
  width: 100%;
  object-fit: cover;
}
.contact{
  padding: 50px !important;
}
@media (max-width: 768px) {
  .card-title {
    color: black !important;
    font-size: 15px;
  }
  .productimagestyle {
    width: 220px;
    height: 220px;
    object-fit: contain;
    padding: 10px;
    background-color: #ffff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Example shadow */
  }
  .contact{
    padding: 20px !important;
  }
}

@media (min-width: 900px) and (max-width: 1400px) {
/* 
  .testcolor{
    background-color: #daba48 !important;
  } */
  .letterstyle {
   
    font-size: 20px !important;
  }
  .navi{
    font-size: 0px !important;
  }
  .contact{
    padding: 20px !important;
  }
}

@media (max-width: 540px) {
  .letterstyle {
    font-size: 20px !important;
  }
  .logostyle {
    height: 50px;
    width: 50px;
  }
  .productimagestyle {
    width: 165px;
    height: 165px;
    object-fit: contain;
    padding: 5px;
    background-color: #ffff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Example shadow */
  }
}

@media (max-width: 400px) {
  .letterstyle {
    font-size: 15px !important;
  }
  .logostyle {
    height: 50px;
    width: 50px;
  }
}
.custom-image {
  object-fit: cover;
}
.custom-images{
  width: 100%;
  height: 50px;
}

/* Media query for responsive design */
@media (max-width: 600px) {
  
  .custom-image {
    object-fit: contain; /* Example: change object-fit for smaller screens */
    /* Add any other styles you need for mobile view */
  }
}
.view-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: white;
  color: rgb(13, 9, 9);
  border: 1px solid #040303; /* Lighter border */
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.view-more-button:hover {
  background-color: white;
  border-color: #c0c0c0; /* Slightly darker border on hover */
}

